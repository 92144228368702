import React, { useState, useEffect } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { updateUser, getRoles, getUserById } from '../../services/adminService';

function EditUserForm({ userId, onClose, onUserUpdated }) {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    cellPhone: '',
    roles: [],
  });
  const [availableRoles, setAvailableRoles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserData(userId);
    fetchRoles();
  }, [userId]);

  const fetchUserData = async (userId) => {
    try {
      const response = await getUserById(userId);
      console.log('Response from getUserById:', response);

      if (response && response.success && response.data) {
        setFormData({
          username: response.data.username,
          email: response.data.email,
          firstname: response.data.firstname,
          lastname: response.data.lastname,
          cellPhone: response.data.cellPhone,
          roles: response.data.roles.map(role => role._id)
        });
      } else {
        console.error('User not found:', userId);
        setError('User not found.');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('An error occurred while fetching user data.');
    }
  };

  const fetchRoles = async () => {
    try {
      const roles = await getRoles();
      // Sort roles by their 'order' property
      roles.sort((a, b) => a.order - b.order);
      setAvailableRoles(roles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleRoleChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      roles: checked
        ? [...prevState.roles, value]
        : prevState.roles.filter(role => role !== value)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUser(userId, formData);
      onUserUpdated();
      onClose();
    } catch (error) {
      console.error('Error updating user:', error);
      setError('Failed to update user');
    }
  };

  return (
    <div className="edit-user-form-container">
      <form onSubmit={handleSubmit} className="edit-user-form">
        <h2>Edit User</h2>
        
        {/* Username and Email on the same row */}
        <div className="form-row">
          <div className="form-group half-width">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group half-width">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* First Name and Last Name on the same row */}
        <div className="form-row">
          <div className="form-group half-width">
            <label htmlFor="firstname">First Name</label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group half-width">
            <label htmlFor="lastname">Last Name</label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="cellPhone">Cell Phone</label>
          <input
            type="tel"
            id="cellPhone"
            name="cellPhone"
            value={formData.cellPhone}
            onChange={handleChange}
          />
        </div>
        
        <div className="form-group">
          <label>Roles</label>
          <div className="roles-group">
            {availableRoles.map(role => (
              <div key={role._id} className="role-checkbox">
                <input
                  type="checkbox"
                  id={role.name}
                  name="roles"
                  value={role._id}
                  checked={formData.roles.includes(role._id)}
                  onChange={handleRoleChange}
                />
                <label htmlFor={role.name}>{role.name}</label>
              </div>
            ))}
          </div>
        </div>

        <div className="form-actions">
          <button type="submit" className="submit-button">Update User</button>
          <button type="button" onClick={onClose} className="cancel-button">Cancel</button>
        </div>
      </form>
    </div>
  );
}

export default EditUserForm;