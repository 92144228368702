import React from 'react';
import '../../App.css';

function DeleteRoleConfirmation({ role, onCancel, onConfirm }) {
  return (
    <div className="modal-overlay">
      <div className="delete-role-confirmation">
        <h2>Delete Role</h2>
        <p>Are you sure you want to delete the role "{role.name}"?</p>
        <p>If users have this role assigned, it will be removed from their accounts.</p>
        <div className="confirmation-actions">
          <button className="confirm-button" onClick={onConfirm}>Yes, Delete</button>
          <button className="cancel-button" onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default DeleteRoleConfirmation;