import axiosInstance from './api';

// Function to get all equipment
export const getEquipment = async () => {
  try {
    const response = await axiosInstance.get('/equipment');
    return response.data;
  } catch (error) {
    console.error('Error fetching equipment:', error);
    throw error;
  }
};

// Function to create new equipment
export const createEquipment = async (equipmentData) => {
  try {
    const response = await axiosInstance.post('/equipment', equipmentData);
    return response.data;
  } catch (error) {
    console.error('Error creating equipment:', error);
    throw error;
  }
};

// Function to create work order related to equipment
export const createWorkOrder = async (workOrderData) => {
  try {
    const response = await axiosInstance.post('/workOrders', workOrderData);
    return response.data;
  } catch (error) {
    console.error('Error creating work order:', error);
    throw error;
  }
};

// Additional functions (updateEquipment, deleteEquipment, etc.) can be added similarly

