import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { login as loginService } from '../services/authService';
import { useAuth } from '../hooks/useAuth';
import ForgotCredentialsModal from './ForgotCredentialsModal';
import '../App.css';

function Login() {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [isForgotModalOpen, setIsForgotModalOpen] = useState(false); // State to control modal
  const [showForgotLink, setShowForgotLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setCredentials({ 
      ...credentials, 
      [e.target.name]: e.target.value 
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const loginResponse = await loginService(credentials.username, credentials.password);
      if (!loginResponse.token || !loginResponse.user) {
        setError('Login failed. Please check your credentials.');
        return;
      }

      localStorage.setItem('token', loginResponse.token);
      localStorage.setItem('user', JSON.stringify(loginResponse.user));
      login(loginResponse.user);
      navigate('/dashboard');
    } catch (err) {
      setShowForgotLink(true);
      console.error('Login error:', err);
      setError(err.message || 'An error occurred during login.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Login</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Username:</label>
            <input 
              type="text" 
              name="username" 
              value={credentials.username} 
              onChange={handleChange} 
              required 
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input 
              type="password" 
              name="password" 
              value={credentials.password} 
              onChange={handleChange} 
              required 
            />
          </div>
          <button type="submit">Login</button>
        </form>
        <div className="register-link">
          <Link to="/register">Don't have an account? Register here.</Link>
        </div>
        {showForgotLink && (
          <button 
            className="forgot-button" 
            onClick={() => setIsForgotModalOpen(true)}
          >
            Forgot Username/Password?
          </button>
        )}
      </div>

      {/* Forgot Credentials Modal */}
      <ForgotCredentialsModal 
        isOpen={isForgotModalOpen} 
        onClose={() => setIsForgotModalOpen(false)} 
      />
    </div>
  );
}

export default Login;