import axiosInstance from './api';

// Fetch users
export const getUsers = async (showDeleted = false) => {
  try {
    const response = await axiosInstance.get(`/users?showDeleted=${showDeleted}`);
    console.log('Users response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in getUsers:', error.response || error);
    throw error;
  }
};

// Create a new user
export const createUser = async (userData) => {
  try {
    console.log('Creating user with data:', userData);
    const response = await axiosInstance.post('/users', userData);
    console.log('Create user response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in createUser:', error);
    console.error('Error response:', error.response);
    throw error;
  }
};

// Update a user
export const updateUser = async (id, userData) => {
  try {
    const response = await axiosInstance.put(`/users/${id}`, userData);
    return response.data;
  } catch (error) {
    console.error('Error in updateUser:', error.response || error);
    throw error;
  }
};

// Reset user password
export const resetUserPassword = async (id) => {
  try {
    const response = await axiosInstance.post(`/users/${id}/reset-password`);
    return response.data;
  } catch (error) {
    console.error('Error in resetUserPassword:', error.response || error);
    throw error;
  }
};

// Get a user by ID
export const getUserById = async (id) => {
  try {
    const response = await axiosInstance.get(`/users/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error in getUserById:', error.response || error);
    throw error;
  }
};

// Soft delete a user
export const softDeleteUser = async (id) => {
  try {
    const response = await axiosInstance.put(`/users/${id}/soft-delete`);
    return response.data;
  } catch (error) {
    console.error('Error in softDeleteUser:', error.response || error);
    throw error;
  }
};

// Reactivate a user
export const reactivateUser = async (id) => {
  try {
    console.log('Attempting to reactivate user with ID:', id);
    const response = await axiosInstance.put(`/users/${id}/reactivate`);
    console.log('Reactivation response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in reactivateUser:', error.response || error);
    throw error;
  }
};

// Fetch roles
export const getRoles = async () => {
  try {
    const response = await axiosInstance.get('/roles');
    console.log('Roles response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in getRoles:', error.response || error);
    throw error;
  }
};

// Create a new role
export const createRole = async (roleData) => {
  try {
    const response = await axiosInstance.post('/roles', roleData);
    return response.data;
  } catch (error) {
    console.error('Error in createRole:', error.response || error);
    throw error;
  }
};

// Update a role
export const updateRole = async (id, roleData) => {
  try {
    const response = await axiosInstance.put(`/roles/${id}`, roleData);
    return response.data;
  } catch (error) {
    console.error('Error in updateRole:', error.response || error);
    throw error;
  }
};

// Delete a role
export const deleteRole = async (id) => {
  try {
    const response = await axiosInstance.delete(`/roles/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error in deleteRole:', error.response || error);
    throw error;
  }
};

// Get a role by ID
export const getRoleById = async (id) => {
  try {
    const response = await axiosInstance.get(`/roles/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error in getRoleById:', error.response || error);
    throw error;
  }
};

// Update roles order
export const updateRolesOrder = async (orderedRoleIds) => {
  try {
    console.log('Updating roles order with:', orderedRoleIds);
    const response = await axiosInstance.put('/roles/order', { roles: orderedRoleIds });
    console.log('Update roles order response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in updateRolesOrder:', error.response?.data || error);
    throw error;
  }
};

// Get users with filter
export const getUsersWithFilter = async (showDeleted = false) => {
  try {
    console.log('Fetching users with showDeleted:', showDeleted);
    const response = await axiosInstance.get(`/users?showDeleted=${showDeleted}`);
    console.log('Users response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in getUsersWithFilter:', error.response || error);
    throw error;
  }
};

// Additional functions can be added here