import React, { useState, useEffect } from 'react';
import { getWorkOrders, deleteWorkOrder } from '../services/workOrderService';
import { checkAbilityPermissions } from '../services/authService';
import { useAuth } from '../hooks/useAuth';
import WorkOrderList from './WorkOrderList';
import WorkOrderDetails from './WorkOrderDetails';
import WorkOrderForm from './WorkOrderForm';
import '../styles/WorkOrders.css';

function WorkOrders() {
  const [workOrders, setWorkOrders] = useState([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [canViewOthers, setCanViewOthers] = useState(false);
  const [canEditOthers, setCanEditOthers] = useState(false);
  const [canDeleteOthers, setCanDeleteOthers] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();
  const [filters, setFilters] = useState({
    status: 'All',
    location: 'All Locations',
    type: 'All Types'
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    fetchWorkOrders();
    checkPermissions();
  }, []);

  const fetchWorkOrders = async () => {
    try {
      setIsLoading(true);
      const data = await getWorkOrders();
      console.log('Fetched work orders:', data);
      if (Array.isArray(data)) {
        setWorkOrders(data);
        console.log('Work orders state updated:', data);
      } else {
        console.error('Received non-array data:', data);
        setWorkOrders([]);
      }
      setError('');
    } catch (err) {
      console.error('Error fetching work orders:', err);
      setError('Failed to fetch work orders.');
      setWorkOrders([]);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissions = async () => {
    const viewPermission = await checkAbilityPermissions('CanViewOthersWorkOrders');
    const editPermission = await checkAbilityPermissions('CanEditOthersWorkOrders');
    const deletePermission = await checkAbilityPermissions('CanDeleteOthersWorkOrders');
    setCanViewOthers(viewPermission);
    setCanEditOthers(editPermission);
    setCanDeleteOthers(deletePermission);
  };

  const handleFilterChange = (filterType, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [filterType]: value }));
    setCurrentPage(1);
  };

  const handleDeleteWorkOrder = async (id) => {
    if (window.confirm('Are you sure you want to delete this work order?')) {
      try {
        await deleteWorkOrder(id);
        fetchWorkOrders();
        setSelectedWorkOrder(null);
      } catch (err) {
        setError('Failed to delete work order.');
      }
    }
  };

  const filteredWorkOrders = Array.isArray(workOrders) ? workOrders.filter(order => {
    return (
      (filters.status === 'All' || order.status === filters.status) &&
      (filters.location === 'All Locations' || order.location.name === filters.location) &&
      (filters.type === 'All Types' || order.type === filters.type) &&
      (canViewOthers || order.createdBy._id === user.id)
    );
  }) : []; // Return an empty array if workOrders is not an array

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredWorkOrders.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="work-orders-container">
      <div className="work-orders-header">
        <button onClick={() => setShowAddForm(true)}>Add Work Order</button>
        <div className="filters">
          <select 
            value={filters.status} 
            onChange={(e) => handleFilterChange('status', e.target.value)}
          >
            <option value="All">All Statuses</option>
            <option value="open">Open</option>
            <option value="postponed">Postponed</option>
            <option value="closed">Closed</option>
          </select>
          <select 
            value={filters.location} 
            onChange={(e) => handleFilterChange('location', e.target.value)}
          >
            <option value="All Locations">All Locations</option>
            {/* Add options for locations */}
          </select>
          <select 
            value={filters.type} 
            onChange={(e) => handleFilterChange('type', e.target.value)}
          >
            <option value="All Types">All Types</option>
            <option value="Vehicle">Vehicle</option>
            <option value="Trailer">Trailer</option>
            <option value="Machine">Machine</option>
            <option value="Equipment">Equipment</option>
          </select>
        </div>
      </div>
      {error && <p className="error-message">{error}</p>}
      {isLoading ? (
        <p>Loading work orders...</p>
      ) : (
        <div className="work-orders-content">
          <WorkOrderList 
            workOrders={currentItems} 
            onSelectWorkOrder={setSelectedWorkOrder} 
          />
          <WorkOrderDetails 
            workOrder={selectedWorkOrder} 
            canEdit={canEditOthers || (selectedWorkOrder && selectedWorkOrder.createdBy._id === user.id)}
            canDelete={canDeleteOthers || (selectedWorkOrder && selectedWorkOrder.createdBy._id === user.id)}
            onDelete={handleDeleteWorkOrder}
          />
        </div>
      )}
      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredWorkOrders.length / itemsPerPage) }, (_, i) => (
          <button key={i} onClick={() => paginate(i + 1)}>
            {i + 1}
          </button>
        ))}
      </div>
      {showAddForm && (
        <WorkOrderForm 
          onClose={() => setShowAddForm(false)} 
          onWorkOrderAdded={fetchWorkOrders}
        />
      )}
    </div>
  );
}

export default WorkOrders;
