import axiosInstance from './api';

export const getLocations = async () => {
  try {
    const response = await axiosInstance.get('/locations');
    return response.data;
  } catch (error) {
    console.error('Error fetching locations:', error);
    throw error;
  }
};