import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { checkPagePermissions, checkAbilityPermissions } from '../services/authService';

function ProtectedRoute({ children, requiredAbility }) {
  const { user } = useAuth();
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkPermissions = async () => {
      if (user) {
        console.log('Current user roles:', user.roles);
        try {
          const page = location.pathname.split('/')[1];
          console.log('Checking permissions for page:', page);
          if (user.roles.includes('Admin')) {
            setHasPermission(true);
          } else {
            const pagePermission = await checkPagePermissions(page);
            console.log('Page permission result:', pagePermission);
            if (pagePermission) {
              if (requiredAbility) {
                const abilityPermission = await checkAbilityPermissions(requiredAbility);
                console.log('Ability permission result:', abilityPermission);
                setHasPermission(abilityPermission);
              } else {
                setHasPermission(true);
              }
            } else {
              setHasPermission(false);
            }
          }
        } catch (error) {
          console.error('Error checking permissions:', error);
          setHasPermission(false);
        }
      }
      setIsLoading(false);
    };

    checkPermissions();
  }, [user, location.pathname, requiredAbility]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!hasPermission) {
    console.log('User does not have permission for:', location.pathname);
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
}

export default ProtectedRoute;