import React from 'react';
import '../styles/WorkOrderList.css';

function WorkOrderList({ workOrders, onSelectWorkOrder }) {
  console.log('WorkOrderList received workOrders:', workOrders);

  return (
    <div className="work-order-list">
      <h2>Work order list</h2>
      {!Array.isArray(workOrders) ? (
        <p>Error: Invalid work orders data</p>
      ) : workOrders.length === 0 ? (
        <p>No work orders found.</p>
      ) : (
        <ul>
          {workOrders.map((workOrder) => (
            <li key={workOrder._id} onClick={() => onSelectWorkOrder(workOrder)}>
              <div className="work-order-item">
                <h3>{workOrder.title}</h3>
                <p>Equipment: {workOrder.type} - {workOrder.equipment?.ID}</p>
                <p>Status: {workOrder.status}</p>
                <p>Created: {new Date(workOrder.createdAt).toLocaleDateString()}</p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default WorkOrderList;
