import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import UserManagement from './Admin/UserManagement';
import RoleManagement from './Admin/RoleManagement';
import Settings from './Admin/Settings';
import '../App.css';

function Admin() {
  const [activeTab, setActiveTab] = useState('users');
  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`/admin/${tab}`);
  };

  return (
    <div className="admin-container">
      <div className="admin-tabs">
        <button
          className={`admin-tab ${activeTab === 'users' ? 'active' : ''}`}
          onClick={() => handleTabChange('users')}
        >
          Users
        </button>
        <button
          className={`admin-tab ${activeTab === 'roles' ? 'active' : ''}`}
          onClick={() => handleTabChange('roles')}
        >
          Roles
        </button>
        <button
          className={`admin-tab ${activeTab === 'settings' ? 'active' : ''}`}
          onClick={() => handleTabChange('settings')}
        >
          Settings
        </button>
      </div>
      <div className="admin-content">
        <Routes>
          <Route path="/" element={<UserManagement />} />
          <Route path="users" element={<UserManagement />} />
          <Route path="roles" element={<RoleManagement />} />
          <Route path="settings" element={<Settings />} />
        </Routes>
      </div>
    </div>
  );
}

export default Admin;