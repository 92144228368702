import React, { useEffect, useState } from 'react';
import { getEquipment } from '../services/equipmentService';
import { Link } from 'react-router-dom';
import '../App.css';

function Equipment() {
  const [equipment, setEquipment] = useState([]); // Ensure initial state is an array
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchEquipment = async () => {
      try {
        const response = await getEquipment();
        if (response.success) {
          setEquipment(response.data); // Set only the equipment array
        } else {
          setError('Failed to fetch equipment.');
        }
      } catch (err) {
        setError('Failed to fetch equipment.');
      }
    };

    fetchEquipment();
  }, []);

  return (
    <div className="equipment-container">
      <div className="equipment-header">
        <h2>Equipment</h2>
        <Link to="/equipment/add" className="add-equipment-button">Add Equipment</Link>
      </div>
      {error && <p className="error-message">{error}</p>}
      <ul className="equipment-list">
        {Array.isArray(equipment) && equipment.map((item) => (
          <li key={item._id}>
            {item.equipmentID} - {item.make} - {item.model} - {item.status}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Equipment;
