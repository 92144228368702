import React, { useState } from 'react';
import { createEquipment } from '../services/equipmentService';
import '../App.css'; // Import the updated CSS for styling

function AddEquipment() {
  const [formData, setFormData] = useState({
    equipmentID: '',
    type: 'Truck',
    make: '',
    model: '',
    year: '',
    serialNumber: '',
    // Common fields
    serviceInterval: '',
    lastServiceDate: '',
    notes: '',
    // Truck-specific fields
    currentMileage: '',
    mileageDate: '',
    lastServiceMileage: '',
    safetyLaneExpirationDate: '',
    licenseExpirationDate: '',
    iftaExpirationDate: '',
    insuranceExpirationDate: '',
    engineDetails: {
      make: '',
      model: '',
      serial: '',
    },
    transmissionDetails: {
      make: '',
      model: '',
      serial: '',
    },
    // Machine-specific fields
    currentHours: '',
    hoursDate: '',
    lastServiceHours: '',
    // Item-specific fields
    warrantyExpiration: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Handle nested fields
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData({
        ...formData,
        [parent]: {
          ...formData[parent],
          [child]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createEquipment(formData);
      setSuccess('Equipment added successfully');
      setFormData({
        equipmentID: '',
        type: 'Truck',
        make: '',
        model: '',
        year: '',
        serialNumber: '',
        serviceInterval: '',
        lastServiceDate: '',
        notes: '',
        currentMileage: '',
        mileageDate: '',
        lastServiceMileage: '',
        safetyLaneExpirationDate: '',
        licenseExpirationDate: '',
        iftaExpirationDate: '',
        insuranceExpirationDate: '',
        engineDetails: {
          make: '',
          model: '',
          serial: '',
        },
        transmissionDetails: {
          make: '',
          model: '',
          serial: '',
        },
        currentHours: '',
        hoursDate: '',
        lastServiceHours: '',
        warrantyExpiration: '',
      });
      setError('');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to add equipment.');
      setSuccess('');
    }
  };

  return (
    <div className="add-equipment-container">
      <form className="add-equipment-form" onSubmit={handleSubmit}>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}

        {/* Equipment ID */}
        <div className="form-group">
          <label>Equipment ID:</label>
          <input type="text" name="equipmentID" value={formData.equipmentID} onChange={handleChange} required />
        </div>

        {/* Type */}
        <div className="form-group">
          <label>Type:</label>
          <select name="type" value={formData.type} onChange={handleChange} required>
            <option value="Truck">Truck</option>
            <option value="Machine">Machine</option>
            <option value="Item">Item</option>
          </select>
        </div>

        {/* Make and Model on the Same Line */}
        <div className="form-row">
          <div className="form-group half-width">
            <label>Make:</label>
            <input type="text" name="make" value={formData.make} onChange={handleChange} />
          </div>
          <div className="form-group half-width">
            <label>Model:</label>
            <input type="text" name="model" value={formData.model} onChange={handleChange} />
          </div>
        </div>

        {/* Year */}
        <div className="form-group">
          <label>Year:</label>
          <input type="number" name="year" value={formData.year} onChange={handleChange} required />
        </div>

        {/* Serial Number */}
        <div className="form-group">
          <label>Serial Number:</label>
          <input type="text" name="serialNumber" value={formData.serialNumber} onChange={handleChange} />
        </div>

        {/* Common Fields */}
        {/* Add other common fields here */}

        {/* Engine Details - Make and Model on the Same Line */}
        {(formData.type === 'Truck' || formData.type === 'Machine') && (
          <>
            <h3>Engine Details</h3>
            <div className="form-row">
              <div className="form-group half-width">
                <label>Engine Make:</label>
                <input type="text" name="engineDetails.make" value={formData.engineDetails.make} onChange={handleChange} />
              </div>
              <div className="form-group half-width">
                <label>Engine Model:</label>
                <input type="text" name="engineDetails.model" value={formData.engineDetails.model} onChange={handleChange} />
              </div>
            </div>
            {/* Engine Serial */}
            <div className="form-group">
              <label>Engine Serial:</label>
              <input type="text" name="engineDetails.serial" value={formData.engineDetails.serial} onChange={handleChange} />
            </div>
          </>
        )}

        {/* Transmission Details - Make and Model on the Same Line */}
        {formData.type === 'Truck' && (
          <>
            <h3>Transmission Details</h3>
            <div className="form-row">
              <div className="form-group half-width">
                <label>Transmission Make:</label>
                <input type="text" name="transmissionDetails.make" value={formData.transmissionDetails.make} onChange={handleChange} />
              </div>
              <div className="form-group half-width">
                <label>Transmission Model:</label>
                <input type="text" name="transmissionDetails.model" value={formData.transmissionDetails.model} onChange={handleChange} />
              </div>
            </div>
            {/* Transmission Serial */}
            <div className="form-group">
              <label>Transmission Serial:</label>
              <input type="text" name="transmissionDetails.serial" value={formData.transmissionDetails.serial} onChange={handleChange} />
            </div>
          </>
        )}

        {/* Machine-specific Fields */}
        {formData.type === 'Machine' && (
          <>
            <h3>Machine Details</h3>
            {/* Add machine-specific fields here */}
            <div className="form-group">
              <label>Current Hours:</label>
              <input type="number" name="currentHours" value={formData.currentHours} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Hours Date:</label>
              <input type="date" name="hoursDate" value={formData.hoursDate} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Last Service Hours:</label>
              <input type="number" name="lastServiceHours" value={formData.lastServiceHours} onChange={handleChange} />
            </div>
          </>
        )}

        {/* Item-specific Fields */}
        {formData.type === 'Item' && (
          <>
            <h3>Item Details</h3>
            <div className="form-group">
              <label>Warranty Expiration:</label>
              <input type="date" name="warrantyExpiration" value={formData.warrantyExpiration} onChange={handleChange} />
            </div>
          </>
        )}

        {/* Submit Button */}
        <div className="form-group">
          <button type="submit">Add Equipment</button>
        </div>
      </form>
    </div>
  );
}

export default AddEquipment;