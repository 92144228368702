import axiosInstance from './api';

// Update user profile
export const updateUserProfile = async (userId, profileData) => {
  const response = await axiosInstance.put(`/users/${userId}/profile`, profileData);
  return response.data;
};

// Change password for the authenticated user
export const changeOwnPassword = async (currentPassword, newPassword) => {
  try {
    const response = await axiosInstance.put('/auth/change-password', { currentPassword, newPassword });
    return response.data;
  } catch (error) {
    console.error('Error in changeOwnPassword:', error.response || error);
    throw error;
  }
};
