import React from 'react';
import '../App.css';

function Reports() {
  return (
    <div>
      <h2 className="page-title">Reports</h2>
      <p>Reports page content will go here.</p>
    </div>
  );
}

export default Reports;