import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { SettingsContext } from '../context/SettingsContext';
import { useAuth } from '../hooks/useAuth';

function Header() {
  const navigate = useNavigate();
  const { siteTitle } = useContext(SettingsContext);
  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout(); // Clears user and token from state and localStorage
    navigate('/login'); // Redirects to login page
  };

  return (
    <header style={styles.header}>
      <div style={styles.leftSection}>
        <img src="/logo.png" alt="FGR Logo" style={styles.logoImage} />
        <h1 style={styles.logo}>{siteTitle}</h1>
      </div>
      <div style={styles.rightSection}>
        {user ? (
          <button onClick={handleLogout} style={styles.logoutButton} title="Logout">
            <FiLogOut size={20} />
          </button>
        ) : (
          <Link to="/login" style={styles.loginLink}>
            Login/Register
          </Link>
        )}
      </div>
    </header>
  );
}

const styles = {
  header: {
    width: '100%',
    height: '40px', // Reduced header height to 40px
    backgroundColor: '#330864', // Updated to purple
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    boxSizing: 'border-box',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '999', // Ensure the header stays above other elements
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
  },
  logoImage: {
    width: '25px', // Adjust size as needed
    height: '25px', // Adjust size as needed
    marginRight: '15px',
    marginLeft: '-10px',
  },
  logo: {
    margin: '0',
    fontSize: '1.2rem',
    color: 'white',
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  loginLink: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1rem',
    transition: 'color 0.3s',
  },
  loginLinkHover: {
    color: '#E1E0E0',
  },
  profileLink: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1rem',
    transition: 'color 0.3s',
  },
  logoutButton: {
    background: 'none',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
  },
};

export default Header;