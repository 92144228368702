import React from 'react';
import { useAuth } from '../hooks/useAuth';
import '../App.css';

function Dashboard() {
  const { user } = useAuth();

  return (
    <div className="dashboard-container">
      <h1 className="page-title">Dashboard</h1>
      <div className="dashboard-content">
        <div className="welcome-message">
          <h2>Welcome, {user.firstname}!</h2>
          <p>This site is currently under construction. Please be patient.</p>
        </div>
        <div className="construction-notice">
          <i className="construction-icon">🚧</i>
          <p>We're working hard to bring you new features and improvements.</p>
          <p>Thank you for your understanding.</p>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;