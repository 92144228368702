import React, { useState, useEffect, useCallback } from 'react';
import { FiUserPlus } from 'react-icons/fi';
import { getUsersWithFilter, softDeleteUser, reactivateUser, resetUserPassword } from '../../services/adminService';
import AddUserForm from './AddUserForm';
import EditUserForm from './EditUserForm';
import '../../App.css';
import { useMediaQuery } from 'react-responsive';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [showEditUserForm, setShowEditUserForm] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const fetchUsers = useCallback(async () => {
    try {
      console.log('Current user roles:', localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).roles : 'No user data');
      const data = await getUsersWithFilter(showDeletedUsers);
      console.log('Fetched users:', data);
      if (data.success && Array.isArray(data.data)) {
        setUsers(data.data);
      } else {
        throw new Error('Unexpected data format');
      }
    } catch (err) {
      console.error('Error fetching users:', err);
      console.error('Error details:', err.response?.data);
      setError('Failed to fetch users: ' + (err.response?.data?.message || err.message));
    }
  }, [showDeletedUsers]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleEditUser = (userId) => {
    setEditingUserId(userId);
    setShowEditUserForm(true);
  };

  const handleDeleteUser = (user) => {
    setUserToDelete(user);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteUser = async () => {
    try {
      await softDeleteUser(userToDelete._id);
      setShowDeleteConfirmation(false);
      fetchUsers();
    } catch (err) {
      console.error('Error deleting user:', err);
      setError('Failed to delete user');
    }
  };

  const handleReactivateUser = async (userId) => {
    try {
      console.log('Attempting to reactivate user:', userId);
      console.log('Current user roles:', localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).roles : 'No user data');
      const result = await reactivateUser(userId);
      console.log('Reactivation result:', result);
      fetchUsers();
    } catch (err) {
      console.error('Error reactivating user:', err);
      console.error('Error details:', err.response?.data);
      setError('Failed to reactivate user: ' + (err.response?.data?.message || err.message));
    }
  };

  const handleResetPassword = async (userId) => {
    console.log('Attempting to reset password for user:', userId);
    try {
      const response = await resetUserPassword(userId);
      console.log('Password reset response:', response);
      fetchUsers();
    } catch (err) {
      console.error('Error resetting password:', err);
      console.error('Error details:', err.response?.data);
      setError('Failed to reset password: ' + (err.response?.data?.message || err.message));
    }
  };

  return (
    <div className="user-management">
      <div className="user-management-header">
        <h2>User Management</h2>
        <div>
          <label>
            <input
              type="checkbox"
              checked={showDeletedUsers}
              onChange={() => setShowDeletedUsers(!showDeletedUsers)}
            />
            Show Deleted Users
          </label>
          <button className="add-user-button" onClick={() => setShowAddUserForm(true)}>
            <FiUserPlus /> Add User
          </button>
        </div>
      </div>
      {error && <p className="error-message">{error}</p>}
      {showAddUserForm && (
        <div className="modal-overlay">
          <AddUserForm
            onClose={() => setShowAddUserForm(false)}
            onUserAdded={fetchUsers}
          />
        </div>
      )}
      {showEditUserForm && (
        <div className="modal-overlay">
          <EditUserForm
            userId={editingUserId}
            onClose={() => setShowEditUserForm(false)}
            onUserUpdated={fetchUsers}
          />
        </div>
      )}
      <div className="user-list">
        <table>
          <thead>
            <tr>
              <th>Username</th>
              {!isMobile && <th>Email</th>}
              {!isMobile && <th>Roles</th>}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user._id}>
                <td>{user.username}</td>
                {!isMobile && <td>{user.email}</td>}
                {!isMobile && <td>{user.roles.map(role => role.name).join(', ')}</td>}
                <td>
                  {!user.isDeleted ? (
                    <>
                      <button className="button button-edit" onClick={() => handleEditUser(user._id)}>Edit</button>
                      <button className="button button-delete" onClick={() => handleDeleteUser(user)}>Delete</button>
                      <button className="button button-reset" onClick={() => handleResetPassword(user._id)}>Reset Password</button>
                    </>
                  ) : (
                    <button className="button button-reactivate" onClick={() => handleReactivateUser(user._id)}>Reactivate</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDeleteConfirmation && (
        <div className="modal-overlay">
          <div className="delete-role-confirmation">
            <h2>Delete User</h2>
            <p>Are you sure you want to delete the user "{userToDelete.username}"?</p>
            <p>This action will deactivate the user account.</p>
            <div className="confirmation-actions">
              <button className="confirm-button" onClick={confirmDeleteUser}>Yes, Delete</button>
              <button className="cancel-button" onClick={() => setShowDeleteConfirmation(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserManagement;