import React, { useState, useEffect } from 'react';
import { getSiteSettings, updateSiteTitle, updateEmailSettings } from '../../services/settingsService';
import '../../App.css';

const Settings = () => {
  const [siteTitle, setSiteTitle] = useState('');
  const [originalSiteTitle, setOriginalSiteTitle] = useState('');
  const [emailSettings, setEmailSettings] = useState({
    username: '',
    password: '',
    smtpServer: '',
    smtpPort: ''
  });
  const [originalEmailSettings, setOriginalEmailSettings] = useState({
    username: '',
    password: '',
    smtpServer: '',
    smtpPort: ''
  });
  const [error, setError] = useState('');

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const settings = await getSiteSettings();
      console.log('Fetched settings:', settings);
      if (settings) {
        setSiteTitle(settings.siteTitle || '');
        setOriginalSiteTitle(settings.siteTitle || '');
        setEmailSettings({
          username: settings.emailSettings?.username || '',
          password: settings.emailSettings?.password || '',
          smtpServer: settings.emailSettings?.smtpServer || '',
          smtpPort: settings.emailSettings?.smtpPort || ''
        });
        setOriginalEmailSettings({
          username: settings.emailSettings?.username || '',
          password: settings.emailSettings?.password || '',
          smtpServer: settings.emailSettings?.smtpServer || '',
          smtpPort: settings.emailSettings?.smtpPort || ''
        });
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
      setError('Failed to fetch settings');
    }
  };

  const handleApplySiteTitle = async () => {
    try {
      await updateSiteTitle(siteTitle);
      setOriginalSiteTitle(siteTitle);
      console.log('Site title updated successfully.');
    } catch (err) {
      console.error('Failed to update site title:', err);
      setError('Failed to update site title.');
    }
  };

  const handleCancelSiteTitle = () => {
    setSiteTitle(originalSiteTitle);
    setError('');
  };

  const handleApplyEmailSettings = async () => {
    try {
      await updateEmailSettings(emailSettings);
      setOriginalEmailSettings(emailSettings);
      console.log('Email settings updated successfully.');
    } catch (err) {
      console.error('Failed to update email settings:', err);
      setError('Failed to update email settings.');
    }
  };

  const handleCancelEmailSettings = () => {
    setEmailSettings(originalEmailSettings);
    setError('');
  };

  const handleEmailSettingChange = (e) => {
    const { name, value } = e.target;
    setEmailSettings(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="settings-container">
      <div className="settings-management-header">
        <h2>Settings Management</h2>
      </div>
      {error && <p className="error-message">{error}</p>}
      <div className="settings-list">
        <table> <h3 className="sub-header">Site Settings</h3> </table>
        <table>      
          
          <tbody>
            <tr>
              <td>Site Title</td>
              <td>
                <input
                  type="text"
                  value={siteTitle}
                  onChange={(e) => setSiteTitle(e.target.value)}
                  className="input-field"
                />
              </td>
              <td>
                <button onClick={handleApplySiteTitle} className="button button-apply">Apply</button>
                <button onClick={handleCancelSiteTitle} className="button button-cancel">Cancel</button>
              </td>
            </tr>
          </tbody>
        </table>

        <h3 className="sub-header">Email Settings</h3>
        <table>
          <tbody>
            <tr>
              <td>Username</td>
              <td>
                <input
                  type="text"
                  name="username"
                  value={emailSettings.username}
                  onChange={handleEmailSettingChange}
                  className="input-field"
                />
              </td>
              <td rowSpan="4">
                <button onClick={handleApplyEmailSettings} className="button button-apply">Apply</button>
                <button onClick={handleCancelEmailSettings} className="button button-cancel">Cancel</button>
              </td>
            </tr>
            <tr>
              <td>Password</td>
              <td>
                <input
                  type="password"
                  name="password"
                  value={emailSettings.password}
                  onChange={handleEmailSettingChange}
                  className="input-field"
                />
              </td>
            </tr>
            <tr>
              <td>SMTP Server</td>
              <td>
                <input
                  type="text"
                  name="smtpServer"
                  value={emailSettings.smtpServer}
                  onChange={handleEmailSettingChange}
                  className="input-field"
                />
              </td>
            </tr>
            <tr>
              <td>Port</td>
              <td>
                <input
                  type="text"
                  name="smtpPort"
                  value={emailSettings.smtpPort}
                  onChange={handleEmailSettingChange}
                  className="input-field"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Settings;