import axiosInstance from './api';

// Fetch current site settings
export const getSiteSettings = async () => {
  try {
    const response = await axiosInstance.get('/settings');
    return response.data;
  } catch (error) {
    console.error('Error fetching settings:', error);
    if (error.response && error.response.status === 401) {
      throw new Error('Unauthorized access. Please log in.');
    } else {
      throw new Error('Failed to fetch settings. Please try again later.');
    }
  }
};

// Update the site title
export const updateSiteTitle = async (siteTitle) => {
  try {
    const response = await axiosInstance.put('/settings/site-title', { siteTitle });
    return response.data;
  } catch (error) {
    console.error('Error updating site title:', error);
    throw new Error('Failed to update site title. Please try again later.');
  }
};

// Update email settings
export const updateEmailSettings = async (emailSettings) => {
  const response = await axiosInstance.put('/settings/email-settings', emailSettings);
  return response.data;
};