import React, { useState } from 'react';
import { updateWorkOrder } from '../services/workOrderService';
import '../styles/WorkOrderDetails.css';

function WorkOrderDetails({ workOrder, canEdit, canDelete, onDelete }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedWorkOrder, setEditedWorkOrder] = useState(workOrder);

  if (!workOrder) {
    return <div className="work-order-details">Select a work order to view details.</div>;
  }

  const handleEdit = () => {
    setIsEditing(true);
    setEditedWorkOrder(workOrder);
  };

  const handleSave = async () => {
    try {
      await updateWorkOrder(workOrder._id, editedWorkOrder);
      setIsEditing(false);
      // You might want to refresh the work order list or update the current work order here
    } catch (error) {
      console.error('Failed to update work order:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedWorkOrder(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="work-order-details">
      {isEditing ? (
        <>
          <input
            type="text"
            name="title"
            value={editedWorkOrder.title}
            onChange={handleChange}
          />
          <select
            name="state"
            value={editedWorkOrder.state}
            onChange={handleChange}
          >
            <option value="Open">Open</option>
            <option value="Postponed">Postponed</option>
            <option value="Closed">Closed</option>
          </select>
          <textarea
            name="description"
            value={editedWorkOrder.description}
            onChange={handleChange}
          />
          <button onClick={handleSave}>Save</button>
          <button onClick={() => setIsEditing(false)}>Cancel</button>
        </>
      ) : (
        <>
          <h2>{workOrder.title}</h2>
          <p><strong>Status:</strong> {workOrder.state}</p>
          <p><strong>Equipment Type:</strong> {workOrder.equipmentType}</p>
          <p><strong>Equipment ID:</strong> {workOrder.equipmentId.ID}</p>
          <p><strong>Location:</strong> {workOrder.location ? workOrder.location.Title : 'Off-Site'}</p>
          {workOrder.offSiteLocation && (
            <div>
              <p><strong>Off-Site Address:</strong></p>
              <p>{workOrder.offSiteLocation.address}</p>
              <p>{workOrder.offSiteLocation.city}, {workOrder.offSiteLocation.state}</p>
            </div>
          )}
          <p><strong>Description:</strong></p>
          <p>{workOrder.description}</p>
          <p><strong>Requested Start Date:</strong> {workOrder.requestedStartDate ? new Date(workOrder.requestedStartDate).toLocaleDateString() : 'Not specified'}</p>
          <p><strong>Created By:</strong> {workOrder.createdBy.username}</p>
          <p><strong>Created At:</strong> {new Date(workOrder.createdAt).toLocaleString()}</p>
          <p><strong>Last Updated:</strong> {new Date(workOrder.updatedAt).toLocaleString()}</p>
          {canEdit && <button onClick={handleEdit}>Edit</button>}
          {canDelete && <button onClick={() => onDelete(workOrder._id)}>Delete</button>}
        </>
      )}
    </div>
  );
}

export default WorkOrderDetails;