import React, { useState, useEffect } from 'react';
import { getEquipment, createEquipment, createWorkOrder } from '../services/equipmentService';
import { getLocations } from '../services/locationService';
import { useAuth } from '../hooks/useAuth';
import { toast } from 'react-toastify';
import '../App.css';

const WorkOrderForm = ({ onClose }) => {
  const { user } = useAuth();
  const [step, setStep] = useState(1);
  const [equipmentType, setEquipmentType] = useState('');
  const [equipmentList, setEquipmentList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [formData, setFormData] = useState({
    equipmentID: '',
    newEquipmentID: '',
    category: '',
    location: '',
    offSiteLocation: { address: '', city: '', state: '', latitude: null, longitude: null },
    hours: '',
    odometer: '',
    title: '',
    description: '',
    dateRequested: ''
  });
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [error, setError] = useState('');

  const validCategories = {
    Vehicle: ['Semi', 'Roll-off', 'Service', 'Car', 'Pickup', 'Other'],
    Trailer: ['Flatbed', 'End Dump', 'Tanker', 'Transfer', 'Dumpster', 'Semi', 'Other'],
    Machine: ['Wheel Loader', 'Skid Steer', 'Crane', 'Forklift', 'Grinder', 'Excavator', 'Air Compressor', 'Trommel', 'Other'],
    Equipment: ['Generator', 'Compressor', 'Conveyor', 'Dumpster', 'Heater', 'A/C', 'Fan', 'Pump', 'Welder', 'Other']
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchEquipment = async (type) => {
    try {
      const equipment = await getEquipment(type);
      setEquipmentList(equipment);
    } catch (err) {
      setError('Failed to fetch equipment.');
    }
  };

  const fetchLocations = async () => {
    try {
      const locations = await getLocations();
      setLocationList(locations);
    } catch (err) {
      setError('Failed to fetch locations.');
    }
  };

  const handleTypeChange = (e) => {
    const type = e.target.value;
    setEquipmentType(type);
    fetchEquipment(type);
    setFormData(prev => ({ ...prev, category: '' })); // Reset category when type changes
    setStep(2);
  };

  const handleEquipmentChange = (e) => {
    const equipmentId = e.target.value;
    console.log('Selected equipment ID:', equipmentId);
    if (equipmentId === 'new') {
      setSelectedEquipment(null);
      setFormData(prev => ({ ...prev, equipmentID: 'new', newEquipmentID: '' }));
    } else {
      const equipment = equipmentList.find(eq => eq._id === equipmentId);
      console.log('Found equipment:', equipment);
      setSelectedEquipment(equipment);
      setFormData(prev => ({
        ...prev,
        equipmentID: equipmentId,
        newEquipmentID: '',
        location: equipment.Location || ''
      }));
    }
    setStep(3);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('offSiteLocation.')) {
      const field = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        offSiteLocation: { ...prev.offSiteLocation, [field]: value }
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleLocationChange = (e) => {
    const locationId = e.target.value;
    setFormData(prev => ({
      ...prev,
      location: locationId,
      offSiteLocation: locationId === 'Off-Site' ? { address: '', city: '', state: '', latitude: null, longitude: null } : null
    }));
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData(prev => ({
            ...prev,
            offSiteLocation: {
              ...prev.offSiteLocation,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
          }));
          // Here you would typically use a reverse geocoding service to get the address, city, and state
          // For now, we'll just set placeholder values
          setFormData(prev => ({
            ...prev,
            offSiteLocation: {
              ...prev.offSiteLocation,
              address: 'Current Location',
              city: 'Current City',
              state: 'Current State'
            }
          }));
        },
        () => {
          setError('Unable to retrieve your location');
        }
      );
    } else {
      setError('Geolocation is not supported by your browser');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      let equipmentId;

      if (formData.equipmentID === 'new') {
        if (!formData.newEquipmentID || !formData.category) {
          setError('Please provide both new equipment ID and category.');
          return;
        }
        const newEquipment = await createEquipment({
          ID: formData.newEquipmentID,
          Type: equipmentType,
          Category: formData.category
        });
        equipmentId = newEquipment._id;
      } else {
        equipmentId = formData.equipmentID;
      }

      const workOrderData = {
        equipment: equipmentId,
        type: equipmentType, // Add this line
        location: formData.location,
        offSiteLocation: formData.location === 'Off-Site' ? formData.offSiteLocation : null,
        hours: formData.hours || null,
        odometer: formData.odometer || null,
        title: formData.title,
        description: formData.description || '',
        dateRequested: formData.dateRequested || null,
      };

      console.log('Submitting work order data:', workOrderData); // Add this line for debugging

      const response = await createWorkOrder(workOrderData);
      console.log('Work order created:', response);
      toast.success('Thank you for submitting your order. It has been successfully submitted.');
      onClose();
    } catch (err) {
      console.error('Error creating work order:', err);
      setError(err.response?.data?.message || 'Failed to create work order.');
    }
  };

  const renderStep1 = () => (
    <div className="form-group">
      <label>Equipment Type:</label>
      <select value={equipmentType} onChange={handleTypeChange} required>
        <option value="">Select Type</option>
        <option value="Vehicle">Vehicle</option>
        <option value="Trailer">Trailer</option>
        <option value="Machine">Machine</option>
        <option value="Equipment">Equipment</option>
      </select>
    </div>
  );

  const renderStep2 = () => {
    console.log('Rendering Step 2. Current equipmentID:', formData.equipmentID);
    return (
      <div className="form-group">
        <label>Equipment ID:</label>
        <div className="equipment-select">
          <select 
            name="equipmentID" 
            value={formData.equipmentID} 
            onChange={handleEquipmentChange} 
            required
          >
            <option value="">Select Equipment</option>
            {equipmentList.map(eq => (
              <option key={eq._id} value={eq._id}>{eq.ID} - {eq.Make} {eq.Model}</option>
            ))}
            <option value="new">Add New Item</option>
          </select>
        </div>
        {formData.equipmentID === 'new' && (
          <>
            <input
              type="text"
              name="newEquipmentID"
              value={formData.newEquipmentID}
              onChange={handleChange}
              placeholder="Enter new Equipment ID"
              required
            />
            <select name="category" value={formData.category} onChange={handleChange} required>
              <option value="">Select Category</option>
              {validCategories[equipmentType].map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </>
        )}
      </div>
    );
  };

  const renderStep3 = () => (
    <>
      <div className="form-group">
        <label>Location:</label>
        <select name="location" value={formData.location} onChange={handleLocationChange} required>
          <option value="">Select Location</option>
          {locationList.map(loc => (
            <option key={loc._id} value={loc._id}>{loc.name}</option>
          ))}
          <option value="Off-Site">Off-Site</option>
        </select>
      </div>
      {formData.location === 'Off-Site' && (
        <div className="form-group">
          <label>Off-Site Location:</label>
          <input
            type="text"
            name="offSiteLocation.address"
            value={formData.offSiteLocation.address}
            onChange={handleChange}
            placeholder="Address"
            required
          />
          <button type="button" onClick={handleGetCurrentLocation}>Use Current Location</button>
          <input
            type="text"
            name="offSiteLocation.city"
            value={formData.offSiteLocation.city}
            onChange={handleChange}
            placeholder="City"
            required
          />
          <input
            type="text"
            name="offSiteLocation.state"
            value={formData.offSiteLocation.state}
            onChange={handleChange}
            placeholder="State"
            required
          />
        </div>
      )}
      {(!selectedEquipment || !selectedEquipment.ignoreHours) && (
        <div className="form-group">
          <label>Hours:</label>
          <input type="number" name="hours" value={formData.hours} onChange={handleChange} />
        </div>
      )}
      {(!selectedEquipment || !selectedEquipment.ignoreOdometer) && (
        <div className="form-group">
          <label>Odometer:</label>
          <input type="number" name="odometer" value={formData.odometer} onChange={handleChange} />
        </div>
      )}
      <div className="form-group">
        <label>Title:</label>
        <input type="text" name="title" value={formData.title} onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Description:</label>
        <textarea name="description" value={formData.description} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>Requested Service Date:</label>
        <input type="date" name="dateRequested" value={formData.dateRequested} onChange={handleChange} />
      </div>
      <div className="form-actions">
        <button type="submit" className="submit-button">Submit</button>
        <button type="button" className="cancel-button" onClick={onClose}>Cancel</button>
      </div>
    </>
  );

  return (
    <div className="modal-overlay">
      <div className="workorder-form-container">
        <h2>Create New Work Order</h2>
        <form onSubmit={handleSubmit}>
          {error && <p className="error-message">{error}</p>}
          {step >= 1 && renderStep1()}
          {step >= 2 && renderStep2()}
          {step >= 3 && renderStep3()}
        </form>
      </div>
    </div>
  );
};

export default WorkOrderForm;
