import React, { useState, useEffect } from 'react';
import { FiPlus } from 'react-icons/fi';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import {
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  updateRolesOrder,
} from '../../services/adminService';
import '../../App.css';
import EditRoleForm from './EditRoleForm';
import DeleteRoleConfirmation from './DeleteRoleConfirmation';

function RoleManagement() {
  const [adminRole, setAdminRole] = useState(null);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState('');
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  // Initialize sensors for DndContext
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  useEffect(() => {
    fetchRoles();
  }, []);


  const fetchRoles = async () => {
    try {
      const data = await getRoles();
      console.log('Fetched roles:', data);
      if (Array.isArray(data)) {
        // Sort roles based on the 'order' field
        data.sort((a, b) => a.order - b.order);

        // Separate the "Admin" role
        const admin = data.find(role => role.name === 'Admin');
        const otherRoles = data.filter(role => role.name !== 'Admin');

        setAdminRole(admin);
        setRoles(otherRoles);
      } else {
        throw new Error('Unexpected data format');
      }
    } catch (err) {
      console.error('Error fetching roles:', err);
      setError('Failed to fetch roles');
    }
  };

  const handleAddRole = async () => {
    try {
      const newRole = { name: 'New Role', allowedPages: [] };
      const createdRole = await createRole(newRole);
      setRoles([...roles, createdRole]); // Add to the bottom of the list
      setSelectedRole(createdRole);
      setShowEditForm(true); // Open the edit form for the new role
    } catch (err) {
      console.error('Error creating role:', err);
      setError('Failed to add role');
    }
  };

  const handleEditRole = (roleId) => {
    const role = roles.find(r => r._id === roleId);
    setSelectedRole(role);
    setShowEditForm(true);
  };

  const closeEditForm = () => {
    setShowEditForm(false);
    setSelectedRole(null);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setSelectedRole(null);
  };

  const handleDeleteRole = (roleId) => {
    const role = roles.find(r => r._id === roleId);
    setSelectedRole(role);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteRole = async () => {
    try {
      await deleteRole(selectedRole._id);
      setRoles(roles.filter(role => role._id !== selectedRole._id));
      setShowDeleteConfirmation(false);
      setSelectedRole(null);
    } catch (err) {
      console.error('Error deleting role:', err);
      setError('Failed to delete role');
    }
  };

  const handleSaveEdit = async (updatedRole) => {
    try {
      await updateRole(updatedRole._id, updatedRole);
      setRoles(
        roles.map(role => (role._id === updatedRole._id ? updatedRole : role))
      );
      setShowEditForm(false);
      setSelectedRole(null);
    } catch (err) {
      console.error('Error updating role:', err);
      setError('Failed to update role');
    }
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = roles.findIndex(role => role._id === active.id);
      const newIndex = roles.findIndex(role => role._id === over.id);
      const newRoles = arrayMove(roles, oldIndex, newIndex);
      setRoles(newRoles);

      // Extract the ordered list of role IDs without the Admin role
      const orderedRoleIds = newRoles.map(role => role._id);

      console.log('Ordered role IDs:', orderedRoleIds);

      try {
        await updateRolesOrder(orderedRoleIds);
        console.log('Roles order updated successfully');
      } catch (err) {
        console.error('Error updating roles order:', err);
        console.error('Error details:', err.response?.data);
        setError('Failed to update roles order');
      }
    }
  };

  return (
    <div className="role-management-container">
      <div className="role-management-header">
        <h2>Role Management</h2>
        <button className="add-role-button" onClick={handleAddRole}>
          <FiPlus /> Add Role
        </button>
      </div>

      {error && <p className="error">{error}</p>}
      <div className="role-list">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={roles.map(role => role._id)}
            strategy={verticalListSortingStrategy}
          >
            <div className="role-table">
              {/* Table Header */}
              <div className="table-header">
                <div className="table-cell">Role Name</div>
                <div className="table-cell">Allowed Pages</div>
                <div className="table-cell">Actions</div>
              </div>

              {/* Table Body */}
              <div className="table-body">
                {/* Admin Role - Fixed at the Top */}
                {adminRole && (
                  <div className="table-row admin-row">
                    <div className="table-cell">
                      {adminRole.name}
                      {adminRole.isDefault && <span className="default-role"> *</span>}
                    </div>
                    <div className="table-cell">
                      {(adminRole.allowedPages || []).join(', ') || 'All Pages'}
                    </div>
                    <div className="table-cell">
                      {/* No actions for Admin role */}
                    </div>
                  </div>
                )}

                {/* Draggable Roles */}
                {roles.map((role) => (
                  <SortableItem key={role._id} id={role._id}>
                    <div className="table-row">
                      <div className="table-cell">
                        {role.name}
                        {role.isDefault && <span className="default-role"> *</span>}
                      </div>
                      <div className="table-cell">
                        {(role.allowedPages || []).join(', ') || 'All Pages'}
                      </div>
                      <div className="table-cell">
                        <button
                          className="button button-edit"
                          onClick={() => handleEditRole(role._id)}
                        >
                          Edit
                        </button>
                        <button
                          className="button button-delete"
                          onClick={() => handleDeleteRole(role._id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </SortableItem>
                ))}
              </div>
            </div>
          </SortableContext>
        </DndContext>
      </div>

      {/* Edit Role Form Modal */}
      {showEditForm && selectedRole && (
        <div className="modal-overlay">
          <EditRoleForm
            role={selectedRole}
            onClose={closeEditForm}
            onSave={handleSaveEdit}
          />
        </div>
      )}

      {/* Delete Role Confirmation Modal */}
      {showDeleteConfirmation && selectedRole && (
        <div className="modal-overlay">
          <DeleteRoleConfirmation
            role={selectedRole}
            onCancel={closeDeleteConfirmation}
            onConfirm={confirmDeleteRole}
          />
        </div>
      )}
    </div>
  );
}

export default RoleManagement;