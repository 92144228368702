import axiosInstance from './api';

// Function to get all work orders
export const getWorkOrders = async () => {
  try {
    const response = await axiosInstance.get('/work-orders');
    console.log('Raw API response:', response); // Log the entire response
    if (response.status === 200 && Array.isArray(response.data)) {
      return response.data;
    } else {
      console.error('Invalid response from server:', response);
      throw new Error('Invalid response from server');
    }
  } catch (error) {
    console.error('Error fetching work orders:', error);
    throw error;
  }
};

// Function to create a new work order
export const createWorkOrder = async (workOrderData) => {
  try {
    const response = await axiosInstance.post('/work-orders', workOrderData);
    return response.data;
  } catch (error) {
    console.error('Error creating work order:', error);
    throw error;
  }
};

// Function to get a work order by ID
export const getWorkOrderById = async (id) => {
  try {
    const response = await axiosInstance.get(`/work-orders/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching work order:', error);
    throw error;
  }
};

// Function to update a work order
export const updateWorkOrder = async (id, updatedData) => {
  try {
    const response = await axiosInstance.put(`/work-orders/${id}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating work order:', error);
    throw error;
  }
};

// Function to delete a work order
export const deleteWorkOrder = async (id) => {
  try {
    const response = await axiosInstance.delete(`/work-orders/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting work order:', error);
    throw error;
  }
};

// Additional functions can be added similarly
