import React, { useState, useEffect } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { createUser, getRoles } from '../../services/adminService';

function AddUserForm({ onClose, onUserAdded }) {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    cellPhone: '',
    roles: [],
    password: '',
    generateRandomPassword: false
  });
  const [showPassword, setShowPassword] = useState(false);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const roles = await getRoles();
      // Sort roles by their 'order' property
      roles.sort((a, b) => a.order - b.order);
      setAvailableRoles(roles);
    } catch (error) {
      console.error('Error fetching roles:', error);
      setError('Failed to fetch roles');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleRoleChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      roles: checked
        ? [...prevState.roles, value]
        : prevState.roles.filter(role => role !== value)
    }));
  };

  const handleRandomPasswordChange = (e) => {
    const { checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      generateRandomPassword: checked,
      password: checked ? generateRandomPassword() : ''
    }));
  };

  const generateRandomPassword = () => {
    return Math.random().toString(36).slice(-8);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      console.log('Submitting user data:', formData);
      const response = await createUser(formData);
      console.log('User creation response:', response);
      if (response.success) {
        setSuccess('User created successfully');
        onUserAdded();
        onClose();
      } else {
        setError(response.message || 'Failed to create user');
      }
    } catch (error) {
      console.error('Error creating user:', error);
      setError(error.response?.data?.message || 'Failed to create user');
    }
  };

  return (
    <div className="add-user-form-container">
      <form onSubmit={handleSubmit} className="add-user-form">
        <h2>Add New User</h2>
        
        {/* Username and Email on the same row */}
        <div className="form-row">
          <div className="form-group half-width">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group half-width">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* First Name and Last Name on the same row */}
        <div className="form-row">
          <div className="form-group half-width">
            <label htmlFor="firstname">First Name</label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group half-width">
            <label htmlFor="lastname">Last Name</label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="cellPhone">Cell Phone</label>
          <input
            type="tel"
            id="cellPhone"
            name="cellPhone"
            value={formData.cellPhone}
            onChange={handleChange}
          />
        </div>
        
        <div className="form-group">
          <label>Roles</label>
          <div className="roles-group">
            {availableRoles.map(role => (
              <div key={role._id} className="role-checkbox">
                <input
                  type="checkbox"
                  id={role.name}
                  name="roles"
                  value={role.name}
                  checked={formData.roles.includes(role.name)}
                  onChange={handleRoleChange}
                />
                <label htmlFor={role.name}>{role.name}</label>
              </div>
            ))}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="password">Password</label>
          <div className="password-input">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              disabled={formData.generateRandomPassword}
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="show-password-button"
            >
              {showPassword ? <FiEyeOff /> : <FiEye />}
            </button>
          </div>
        </div>

        <div className="generate-password-checkbox">
          <input
            type="checkbox"
            id="generateRandomPassword"
            name="generateRandomPassword"
            checked={formData.generateRandomPassword}
            onChange={handleRandomPasswordChange}
          />
          <label htmlFor="generateRandomPassword">Generate Random Password</label>
        </div>

        <div className="form-actions">
          <button type="submit" className="submit-button">Add User</button>
          <button type="button" onClick={onClose} className="cancel-button">Cancel</button>
        </div>
      </form>
    </div>
  );
}

export default AddUserForm;