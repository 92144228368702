import React from 'react';
import '../App.css';

function Parts() {
  return (
    <div>
      <h2 className="page-title">Parts</h2>
      <p>Parts page content will go here.</p>
    </div>
  );
}

export default Parts;