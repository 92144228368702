import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import RegisterUser from './components/RegisterUser';
import Admin from './components/Admin';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Profile from './components/Profile';
import Unauthorized from './components/Unauthorized';
import { AuthProvider } from './hooks/useAuth';
import { SettingsProvider } from './context/SettingsContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Equipment from './components/Equipment';
import WorkOrders from './components/WorkOrders';
import Parts from './components/Parts';
import Reports from './components/Reports';
import AddEquipment from './components/AddEquipment';
import WorkOrderForm from './components/WorkOrderForm';

function App() {
  return (
    <Router>
      <AuthProvider>
        <SettingsProvider>
          <div className="app-container">
            <Header />
            <div className="content-container">
              <Sidebar />
              <main>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<RegisterUser />} />
                  <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                  <Route path="/unauthorized" element={<Unauthorized />} />
                  <Route path="/admin/*" element={<ProtectedRoute><Admin /></ProtectedRoute>} />
                  <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                  <Route path="/workorders" element={<ProtectedRoute><WorkOrders /></ProtectedRoute>} />
                  <Route path="/workorders/add" element={<ProtectedRoute requiredAbility="create_work_order"><WorkOrderForm /></ProtectedRoute>} />
                  <Route path="/equipment" element={<ProtectedRoute><Equipment /></ProtectedRoute>} />
                  <Route path="/equipment/add" element={<ProtectedRoute requiredAbility="add_equipment"><AddEquipment /></ProtectedRoute>} />
                  <Route path="/parts" element={<ProtectedRoute><Parts /></ProtectedRoute>} />
                  <Route path="/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                </Routes>
              </main>
            </div>
          </div>
          <ToastContainer />
        </SettingsProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;