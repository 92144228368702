import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import '../../App.css';

function EditRoleForm({ role, onClose, onSave }) {
  const [formData, setFormData] = useState({
    name: role.name,
    allowedPages: role.allowedPages || [],
    abilities: role.abilities || [],
    isDefault: role.isDefault || false,
  });

  const pages = ['Dashboard', 'Work Orders', 'Equipment', 'Parts', 'Reports', 'Admin', 'Profile'];
  const abilities = [
    'CanViewOthersWorkOrders',
    'CanEditOthersWorkOrders',
    'CanDeleteOthersWorkOrders',
    'CanCommentOnOthersWorkOrders',
    'CanAddEquipment',
    'CanAddParts',
    'CanModifyEquipment',
    'CanDeleteEquipment',
    'CanModifyParts',
    'CanDeleteParts',
    'CanOrderParts',
    'CanUpdateEquipmentMeters'
  ];

  const handleChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ ...role, ...formData });
  };

  return (
    <div className="modal-overlay">
      <div className="edit-role-form">
        <h2>Edit Role</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Role Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={(e) => handleChange('name', e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <h3>Allowed Pages</h3>
            {pages.map(page => (
              <div key={page} className="switch-container">
                <label>{page}</label>
                <Switch
                  onChange={(checked) => {
                    const newAllowedPages = checked
                      ? [...formData.allowedPages, page]
                      : formData.allowedPages.filter(p => p !== page);
                    handleChange('allowedPages', newAllowedPages);
                  }}
                  checked={formData.allowedPages.includes(page)}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                />
              </div>
            ))}
          </div>

          <div className="form-group">
            <h3>Abilities</h3>
            {abilities.map(ability => (
              <div key={ability} className="switch-container">
                <label>{ability}</label>
                <Switch
                  onChange={(checked) => {
                    const newAbilities = checked
                      ? [...formData.abilities, ability]
                      : formData.abilities.filter(a => a !== ability);
                    handleChange('abilities', newAbilities);
                  }}
                  checked={formData.abilities.includes(ability)}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                />
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="isDefault"
                checked={formData.isDefault}
                onChange={(e) => handleChange('isDefault', e.target.checked)}
              />
              Set as Default Role for New Users
            </label>
          </div>

          <div className="form-actions">
            <button type="submit" className="submit-button">Save</button>
            <button type="button" className="cancel-button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditRoleForm;