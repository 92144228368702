import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

console.log('API URL:', process.env.REACT_APP_API_URL);

// Create an Axios instance
const API_URL =
  process.env.REACT_APP_API_URL || 'https://falconworkorders.com/api';

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

// Request interceptor to add the token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Ensure token is stored in localStorage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log('Auth Header:', config.headers['Authorization']);
    } else {
      console.log('No token found in localStorage');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { status, data } = error.response;
      
      // Check if the error is due to token expiration
      if (status === 401 && data.message && data.message.toLowerCase().includes('expired')) {
        // Display a notification to the user
        toast.error('Your session has expired. Please log in again.');
        
        // Clear user data from localStorage
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        
        // Redirect to the login page
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;