import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiHome, FiTool, FiTruck, FiBox, FiInbox, FiTerminal, FiUser } from 'react-icons/fi';
import { useAuth } from '../hooks/useAuth';
import { checkPagePermissions } from '../services/authService';

function Sidebar() {
  const { user } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [allowedPages, setAllowedPages] = useState([]);

  const menuItems = [
    { name: 'Dashboard', path: '/dashboard', icon: <FiHome /> },
    { name: 'Work Orders', path: '/workorders', icon: <FiTool /> },
    { name: 'Equipment', path: '/equipment', icon: <FiTruck /> },
    { name: 'Parts', path: '/parts', icon: <FiBox /> },
    { name: 'Reports', path: '/reports', icon: <FiInbox /> },
    { name: 'Admin', path: '/admin', icon: <FiTerminal /> },
    { name: 'Profile', path: '/profile', icon: <FiUser /> },
  ];

  useEffect(() => {
    const fetchAllowedPages = async () => {
      if (user) {
        console.log('Current user roles:', user.roles);
        if (user.roles.includes('Admin')) {
          setAllowedPages(menuItems.map(item => item.path));
        } else {
          const pages = await Promise.all(
            menuItems.map(async (item) => {
              const hasPermission = await checkPagePermissions(item.path.split('/')[1]);
              console.log(`Permission for ${item.path}: ${hasPermission}`);
              return hasPermission ? item.path : null;
            })
          );
          setAllowedPages(pages.filter(Boolean));
        }
      }
    };

    fetchAllowedPages();
  }, [user]);

  console.log('Allowed pages:', allowedPages);

  const handleMenuItemClick = (path) => {
    navigate(path);
    setIsExpanded(false);
  };

  const styles = {
    sidebar: {
      position: 'fixed',
      top: '40px',
      left: '0',
      height: 'calc(100vh - 40px)',
      backgroundColor: '#330864',
      transition: 'width 0.3s',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingTop: '10px',
      zIndex: '1000',
      width: isExpanded ? '160px' : '40px',
      overflowY: 'auto',
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '10px',
      boxSizing: 'border-box',
      color: 'white',
      textDecoration: 'none',
      fontSize: '1rem',
      transition: 'background 0.3s, padding-left 0.3s',
      height: '40px',
      paddingLeft: isExpanded ? '15px' : '10px',
      justifyContent: isExpanded ? 'flex-start' : 'center',
    },
    icon: {
      marginRight: isExpanded ? '15px' : '0px',
      minWidth: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.2rem',
      transition: 'margin-right 0.3s',
    },
    text: {
      whiteSpace: 'nowrap',
      display: 'inline-block',
      transition: 'opacity 0.3s',
      opacity: isExpanded ? 1 : 0,
    },
    subItem: {
      paddingLeft: isExpanded ? '25px' : '5px',
      fontSize: '0.9rem',
    },
    activeIcon: {
      color: '#4CAF50',
    },
    subItemIcon: {
      marginRight: '10px',
      fontSize: '1rem',
    },
  };

  const renderMenuItem = (item) => {
    if (!allowedPages.includes(item.path)) {
      return null;
    }

    const isActive = location.pathname.startsWith(item.path);

    return (
      <div
        key={item.name}
        style={{
          ...styles.menuItem,
          backgroundColor: isActive ? '#0E2C62' : 'transparent',
          cursor: 'pointer',
        }}
        onClick={() => handleMenuItemClick(item.path)}
      >
        <div style={{...styles.icon, ...(isActive ? styles.activeIcon : {})}}>
          {item.icon}
        </div>
        {isExpanded && <span style={styles.text}>{item.name}</span>}
      </div>
    );
  };

  return (
    <div
      style={styles.sidebar}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      aria-expanded={isExpanded}
      role="navigation"
    >
      {menuItems.map((item) => renderMenuItem(item))}
    </div>
  );
}

export default Sidebar;